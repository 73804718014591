<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
                    <el-breadcrumb-item>审核列表</el-breadcrumb-item>
                    <el-breadcrumb-item>审查</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form label-width="150px" ref="form" :model="form">
            <div class="admin_main_block admin_m15">
                <div class="admin_main_block_top">
                    <div class="admin_main_block_right">
                        <el-row>
                            <el-col :span="8">
                                <el-button type="primary" @click="success('form')" v-if="showBtn">审核通过</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button type="warning" @click="reject('form')" v-if="showBtn">审核拒绝</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="admin_form_main">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="任务" name="first">
                            <el-form-item label="标题" prop="taskName"
                                          :rules="[{ required: true, message: '请填标题', trigger: 'blur' },]">
                                <el-input type="text" placeholder="" :disabled="disabled"
                                          v-model="form.taskName"></el-input>
                            </el-form-item>
                            <el-form-item label="金额" prop="money"
                                          :rules="[{ required: true, message: '请填金额', trigger: 'blur' },]">
                                <el-input type="number" placeholder="" :disabled="disabled"
                                          v-model="form.money"></el-input>
                            </el-form-item>
                            <el-form-item label="图标">
                                <el-tooltip class="item" effect="dark" content="点击浏览图片" placement="top-start">
                                    <el-image :src="form.taskPic"
                                              :preview-src-list="[form.taskPic]"
                                    >
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                </el-tooltip>
                            </el-form-item>
                            <el-form-item label="标签">
                                <el-tag v-for="(item,key) in form.label.split(',')" :key="key">{{item}}</el-tag>
                            </el-form-item>
                            <el-form-item label="时间" prop="taskTime"
                                          :rules="[{ required: true, message: '请填时间', trigger: 'blur' },]">
                                <el-input type="text" placeholder="27分钟" :disabled="disabled"
                                          v-model="form.taskTime"></el-input>
                            </el-form-item>
                            <el-form-item label="须知" prop="instructions"
                                          :rules="[{ required: true, message: '请填须知', trigger: 'blur' },]">
                                <el-input placeholder="" type="textarea"
                                          :rows="10" :disabled="disabled" v-model="form.instructions"></el-input>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="步骤" name="second">
                            <el-form-item :label="(key+1)+''" class="step" v-for="(item ,key) in form.taskStep"
                                          :key="key">
                                <!--                                类型【10-添加图片步骤,20-添加文字步骤,30-添加复制文案步骤,40-添加下载链接-->
                                <el-row>
                                    <el-col :span="span8">是否需要上传验证：</el-col>
                                    <el-col :span="span8">
                                        <el-button type="warning" v-if="item.isCheck === 0">否</el-button>
                                        <el-button type="success" v-else>是</el-button>
                                    </el-col>
                                    <el-col :span="span8"></el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="span8">
                                        <el-tooltip class="item" effect="dark" content="点击浏览图片" placement="top-start"
                                                    v-if="item.type === '10'">
                                            <el-image :src="item.content"
                                                      :preview-src-list="[item.content]"
                                            >
                                                <div slot="error" class="image-slot">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </el-image>
                                        </el-tooltip>
                                        <el-link v-else-if="item.type === '40'" :href="item.content" target="_blank">
                                            {{item.content}}
                                        </el-link>
                                        <el-button v-else-if="item.content" type="primary">{{item.content}}</el-button>
                                    </el-col>
                                    <el-col :span="span8">{{item.descr}}</el-col>
                                    <el-col :span="span8">
                                        <el-tooltip v-if="item.pic" class="item" effect="dark" content="点击浏览图片" placement="top-start">
                                            <el-image :src="item.pic"
                                                      fit="scale-down"
                                                      :preview-src-list="[item.pic]"
                                            >
                                                <div slot="error" class="image-slot">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </el-image>
                                        </el-tooltip>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="资料" name="third">
                            <el-form-item v-for="(item,index) in form.receiveData" :label="index" :key="item">
                                <el-input type="text" :placeholder="item" :disabled="disabled"></el-input>
                            </el-form-item>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                span8: 8,
                activeName: "first",
                /*定义前一个活动Tab,初始化默认为 ActiveTab */
                oldTab: "add",
                /*定义Tab是否加载-第一个默认加载 */
                firstIs: true,
                secondIs: false,
                forthIs: false,
                fifthIs: false,
                sixthIs: false,
                thirdIs: false,
                disabled: true,
                showBtn:false,
                form: {
                    id: '',// 用户任务id
                    taskId: '',// 任务id
                    taskName: '',// 任务名称
                    money: '',// 任务金额
                    taskPic: '',// 任务图标
                    label: '',// 任务标签
                    taskTime: '',// 做任务时间
                    instructions: '',// 任务须知
                    // 任务步骤集合
                    taskStep: [
                        // id   :'',// 任务步骤id
                        // isCheck  :'',// 是否需要校验【0-不需要  1-需要】
                        // type  :'',// 类型【10-添加图片步骤,20-添加文字步骤,30-添加复制文案步骤,40-添加下载链接】
                        // descr  :'',// 描述
                        // content  :'',// 内容
                        // picList  :'',// 图片集合
                    ],

                },
            }
        },
        created() {
            this.initData()
        },
        methods: {
            handleClick(tab) {
                this[tab.name + "Is"] = true;
                if (tab.name !== this.oldTab) {
                    this[this.oldTab + "Is"] = false;
                    this.oldTab = tab.name;
                }
            },
            submitForm() {
                let url = this.$api.taskUserHeadReview;
                console.log("submitForm", this.form)
                this.$post(
                    url,
                    {
                        id: this.$route.query.id,
                        status: 20,
                    }
                ).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功",
                        });
                        this.$router.push({name: 'auditList'});
                    } else {
                        this.$message({
                            type: "info",
                            message: res.msg,
                        });
                    }
                });
            },
            success() {
                if (!this.$route.query.id) {
                    this.$message({
                        type: "warning",
                        message: "参数错误！",
                    });
                    return false;
                }
                this.$confirm("是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.submitForm();
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
            reject() {
                this.$prompt("不通过理由", "拒绝", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(({value}) => {
                    if(!value || value.length < 1){
                        this.$message({
                            type: "info",
                            message: "请填写拒绝理由",
                        });
                        return false;
                    }
                    this.$post(
                        this.$api.taskUserHeadReview,
                        {
                            id: this.$route.query.id,
                            status: 30,
                            reviewDesc: value
                        }
                    ).then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                type: "success",
                                message: "成功",
                            });
                            this.$router.push({name: 'auditList'});
                        } else {
                            this.$message({
                                type: "info",
                                message: res.msg,
                            });
                        }
                    });
                });
            },
            initData() {
                this.$get(this.$api.taskUserDetail + this.$route.query.id, {}).then((res) => {
                    if (res.code === 1) {
                        this.form = res.data;
                        this.isShowBtn();
                    }
                });
            },
            isShowBtn(){
                if(this.isInArray(['10'],this.form.status) !== -1){
                    this.showBtn = true;
                }else{
                    this.showBtn = false;
                }
            },
            isInArray(array, str) {
                return array.findIndex(item => item === str);// -1 说明array中不存在id为str的对象
            },
        },
    }
</script>

<style lang="scss" scoped>
    .el-breadcrumb {
        line-height: 32px;
    }

    .admin_main_block_right {
        width: 280px;
    }

    /deep/
    .image-slot {
        font-size: 30px;
    }

    .el-image {
        width: 100px;
    }

    .el-tag {
        margin-right: 10px;
    }

    .admin_form_main .el-form-item {
        width: 600px;
    }

    /deep/
    .step {
        width: 99% !important;
        border: #d9d9d9 1px solid;
        padding-top: 10px;

        .el-form-item__label {
            text-align: center !important;
            font-size: 28px;
        }

        .el-col-8 {
            padding: 10px;
        }
    }

</style>
